<template>
  <div>
    <div class="header"></div>
    <img src="~@/assets/official.jpg" class="img" />
    <div class="footer f-c-c " @click="handleJump">广东柏斯融媒体科技有限公司 版权所有©-{{new Date().getFullYear()}} 粤ICP备2021100843号-1</div>
  </div>
</template>

<script>
export default {
  name: 'Official',
  methods: {
    handleJump() {
      window.open('https://beian.miit.gov.cn')
    }
  }
};
</script>

<style lang="scss" scoped>
.img {
  width: 100vw;
}
.footer {
  font-size: 12px;
  cursor: pointer;
}
</style>
